<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>姓名：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.name" placeholder="退款学生姓名"></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>身份证号：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.id_card" placeholder="退款学生身份证号"></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne">
                <el-button type="primary" @click="openExport">导出</el-button>
            </div>
        </div>
        <div class="baseTable" v-loading="tableLoading">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                         :public-table-configure="{rowKey:'current_transaction_id'}">
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="id_card" label="身份证号"></el-table-column>
                <el-table-column prop="refund_reason" label="退款原因"></el-table-column>
                <el-table-column prop="refund_time" label="申请时间" width="110"></el-table-column>
                <el-table-column prop="current_order_no" label="商户单号"></el-table-column>
                <el-table-column prop="current_transaction_id" label="支付单号"></el-table-column>
                <el-table-column prop="current_pay_time" label="支付时间" width="110"></el-table-column>
                <el-table-column prop="current_price" label="缴费金额"></el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <template v-if="scope.row.operateShow">
                            <el-link class="tableButton" type="primary" @click.prevent="applyPass(scope.row,7)">
                                申请通过
                            </el-link>
                            <el-link class="tableButton" type="danger" @click.prevent="applyPass(scope.row,-1)">
                                申请拒绝
                            </el-link>
                        </template>
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
    </div>
</template>

<script>

import publicPage from '../../components/publicPage.vue'

import publicTable from '../../components/publicTable.vue'

import {businessGet, businessPost} from '../../api/business.js'

import {utilsExportExcel} from "../../utils/utils.js";

export default {
    name: "paySignUpApply",
    components: {
        publicPage,
        publicTable
    },
    data() {
        return {
            searchForm: {
                name: '',
                id_card: '',
            },
            searchHeight: 0,
            tableLoading: false,
            tableData: [],
            total: 0,
        }
    },
    created() {
        this.$nextTick(() => {
            let baseSearchDom = document.querySelector('.baseSearch');
            let baseOperateDom = document.querySelector('.baseOperate');
            let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
            this.searchHeight = searchHeight;
            this.search();
        })
    },
    methods: {
        openExport() {
            let url = '/admin/v1/sign_up/archives?is_export=1&module=1&admission_status=6';
            for (let k in this.searchForm) {
                if (this.searchForm[k]) {
                    url = url + '&' + k + '=' + this.searchForm[k];
                }
            }
            utilsExportExcel(url)
        },
        applyPass(val, status) {
            if (status == 7) {
                this.$confirm('此操作将同意 ' + val.name + ' 的退款申请, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showInput: true,
                    inputPlaceholder: '备注：',
                    type: 'warning',
                }).then(({value}) => {
                    let url = '/admin/v1/sign_up/archives/status';
                    let data = {
                        archives_id: val.id,
                        admission_status: status,
                        refund_desc: value
                    };
                    businessPost(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            }
            else if (status == -1) {
                this.$confirm('此操作将拒绝 ' + val.name + ' 的退款申请, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/sign_up/archives/status';
                    let data = {
                        archives_id: val.id,
                        admission_status: status,
                        is_revoke:2,
                    };
                    businessPost(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            }
        },
        cleanAll() {
            this.searchForm.name = '';
            this.searchForm.id_card = '';
            this.search();
        },
        search() {
            this.$refs.publicPageUserStudent.currentPage = 1;
            this.getTableData();
        },
        getTableData() {
            let url = '/admin/v1/sign_up/archives?module=1&admission_status=6&page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
            for (let k in this.searchForm) {
                if (this.searchForm[k]) {
                    url = url + '&' + k + '=' + this.searchForm[k];
                }
            }
            this.tableLoading = true;
            businessGet(url).then(res => {
                this.tableLoading = false;
                if (res.data.status == 200) {
                    this.total = res.data.data.count;
                    let tableData = res.data.data.list;
                    tableData.forEach(item => {
                        item.children = [];
                        item.order_list.forEach((son, sonIndex) => {
                            if (sonIndex == 0) {
                                item.current_price = son.price;
                                item.current_transaction_id = son.transaction_id;
                                item.current_order_no = son.order_no;
                                item.current_pay_time = son.pay_time;
                                item.operateShow = true;
                            }
                            else {
                                let newItem = JSON.parse(JSON.stringify(item))
                                newItem.current_price = son.price;
                                newItem.current_transaction_id = son.transaction_id;
                                newItem.current_order_no = son.order_no;
                                newItem.current_pay_time = son.pay_time;
                                newItem.operateShow = false;
                                item.children.push(newItem)
                            }
                        })
                    })
                    this.tableData = tableData;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
    }
}
</script>

<style scoped>

@import "../../assets/css/base.css";

.container {
    width: 100%;
    height: 600px;
    box-sizing: border-box;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

</style>
